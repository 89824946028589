import * as React from "react";
import { Layout } from "../components/global/Layout";
import { About } from "../components/about/About";

export default () => {
  return (
    <Layout>
      {/* <About /> */}
      <div className="flex items-center flex-col justify-center w-full p-4">
        <span className="block text-xl mt-20 mb-10">
          This page is currently under construction. Please, visit later.
        </span>
        <img
          className="mb-10"
          src="https://cdn.pixabay.com/photo/2017/06/20/08/12/maintenance-2422173_960_720.png"
          alt="Under construction"
        />
      </div>
    </Layout>
  );
};
